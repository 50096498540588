import sortBy from 'lodash/sortBy'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import seedrandom from 'seedrandom'

import { asVideoType } from '../../types/video'
import type { ContentfulClient } from '../client'
import { makeQueryFunction, makeUseQueryHook } from '../functions'
import { GetVideoDocument } from '../queries/GetVideo.graphql'
import type { GetVideosByTagsQuery } from '../queries/GetVideosByTags.graphql'
import { GetVideosByTagsDocument } from '../queries/GetVideosByTags.graphql'

export enum AssetTag {
    PayMePage = 'paymepage',
}

const randomizeAndMapVideos = (
    data: GetVideosByTagsQuery['videos'],
    seed: string
) => {
    const items = data?.items ?? []
    const random = seedrandom(seed)
    return sortBy(items, (_) => random.int32()).map(asVideoType)
}

export const useRandomVideos = (tags: AssetTag[] = []) => {
    const router = useRouter()
    const result = useGetVideosByTags({
        pause: !tags.length,
        variables: { tags },
    })

    const toVideos = useCallback(
        (data: GetVideosByTagsQuery['videos'], seed: string) => {
            return randomizeAndMapVideos(data, seed)
        },
        [router.asPath, result]
    )

    const [videos, setVideos] = useState(() =>
        toVideos(result.data.videos, router.asPath)
    )

    useEffect(() => {
        if (!result.data || videos.length > 1) return
        setVideos(toVideos(result.data.videos, router.asPath))
    }, [result])

    return {
        ...result,
        videos,
    }
}

export const getRandomVideosByTags = async (
    client: ContentfulClient,
    tags: AssetTag[] = [],
    seed = ''
) => {
    const result = await getVideosByTags(client, {
        variables: {
            tags,
        },
    })

    return randomizeAndMapVideos(result?.data?.videos, seed)
}

export const useGetVideo = makeUseQueryHook({
    query: GetVideoDocument,
})

export const getVideo = makeQueryFunction({
    query: GetVideoDocument,
})

export const useGetVideosByTags = makeUseQueryHook({
    query: GetVideosByTagsDocument,
})

export const getVideosByTags = makeQueryFunction({
    query: GetVideosByTagsDocument,
})
