import type { LiteralUnion } from 'type-fest'

import type { VideoFragment } from '../graphql/fragments/VideoFragment.graphql'
import { EMPTY_COLLECTION } from '../graphql/placeholders'

export interface VideoType {
    /**
     * Alternative text, for accessibility purposes.
     */
    alt?: string

    /**
     * URLs of the video.
     */
    sources: VideoSourceType[]

    /**
     * Thumbnail image to display when the player is inactive.
     */
    poster?: string

    /**
     * gif — muted, autoplayed, looped, no controls, plays inline.
     * video — has sound, controls and is not autoplayed
     * @default video
     */
    preset: VideoPresetType
}

export interface VideoSourceType {
    contentType: VideoContentType
    source: string
}

export function isVideo(something: unknown): something is VideoType {
    const { sources, alt, poster } = something as VideoType
    return (
        Array.isArray(sources) &&
        sources[0] !== undefined &&
        alt !== undefined &&
        poster !== undefined
    )
}

export function asVideoType(props: VideoFragment) {
    const { files = EMPTY_COLLECTION, alt = '', poster, preset, loop } = props
    return {
        preset: preset === 'gif' ? 'gif' : 'video',
        alt,
        poster: poster?.source,
        sources: files.items.map(({ contentType, source }) => ({
            contentType,
            source: source,
        })),
        loop,
    } as VideoType
}

export type VideoContentType = LiteralUnion<'video/mp4' | 'video/webm', string>
export type VideoPresetType = 'video' | 'gif'
